import React, { useState, useEffect } from "react";
import api from "../services/api";
import "../pages/news.less";
import { Link } from 'gatsby';
import "../pages/myPagination.less";
import SEO from "../components/seo";

const NewsPage = (props: any) => {

  //获取接口数据
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  //分页
  const handlePageChange = (page: any, e: any) => {
    if (!page) {
      e.preventDefault();
      return;
    }
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPage; i++) {
      pageNumbers.push(
        <li key={i} className={i === currentPage ? "active" : ""}>
          <a onClick={(e) => handlePageChange(i, e)}>{i}</a>
        </li>
      );
    }
    return pageNumbers;
  };

  //获取列表数据
  const fetchNewsList = async () => {
    try {
      const queryParams = {
        size: 12,
        page: currentPage,
        order: 'orderNum',
        sort: 'desc',
        typeDict: null as null | number
      };
      const params = new URLSearchParams(props.location.search);
      const newsType = params.get('type');
      if (newsType) {
        queryParams.typeDict = +newsType;
      }
      const response = await api.post('/api/app/article/info/page', queryParams);
      const newsData = response.data.data.list;
      setNewsList(newsData);
      setTotalPage(Math.ceil(response.data.data.pagination.total / response.data.data.pagination.size));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchNewsList();
  }, [currentPage, totalPage, props.location]);

  return (
    <div className='news'>
      <div className="news-title"><span>新闻资讯</span></div>

      <section className="news-list" >
        {
          newsList.map((item: any, index: any) => (
            <Link key={item.id} to={`/newsDetail?id=${item.id}`}>
              <div className="news-list-item">
                <div className="item">
                  <div className="tips">{item.typeName}</div>
                  <img className="cover" src={item.cover} />
                  <div className="contain">
                    <div className="time">{item.releaseTime.split(' ')[0]}</div>
                    <div className="info">{item.title}</div>
                    <div className="user">
                      <img src={item.avatar} />
                      <span>{item.author}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))
        }

      </section>
      {/* 分页标签 */}
      <div className="pagination">
        <ul>
          <li>
            <a onClick={(e) => handlePageChange(currentPage > 1 ? currentPage - 1 : 0, e)}>&laquo;</a>
          </li>
          {renderPageNumbers()}
          <li>
            <a onClick={(e) => handlePageChange(totalPage > currentPage ? currentPage + 1 : 0, e)}>&raquo;</a>
          </li>
        </ul>
      </div>

    </div>
  )
}

export default NewsPage;

export const Head = () => (<SEO />)